const container = document.querySelector("[data-js-video-container]")
const button = document.querySelector("[data-js-video-button]")
const thumbnail = document.querySelector("[data-js-video-thumbnail]")
const iframe =
	'<iframe width="560" height="315" src="https://www.youtube.com/embed/WT6bnnDdm2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'

const Video = {
	handleClick: (e) => {
		if (button.contains(e.target)) {
			Video.insertIframe()
		}
	},

	insertIframe: () => {
		button.style.display = "none"
		thumbnail.style.display = "none"
		container.innerHTML = iframe
	},
}

export default Video
