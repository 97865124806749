// import Swiper JS
import Swiper, { Pagination } from "swiper"

const TestimonySlider = {
	init: () => {
		return new Swiper(".swiper", {
			modules: [Pagination],

			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				bulletElement: "button",
			},
		})
	},
}

export default TestimonySlider
