const pins = document.querySelectorAll("[data-js-map-pin]")
const modals = document.querySelectorAll("[data-js-map-modal]")
const modalCloseButtons = document.querySelectorAll("[data-js-close-map-modal]")
const mapBackground = document.querySelector("#js-map-bg")
const cssActiveClass = "is-active"

const Map = {
	handleClick: (e) => {
		if (!pins.length) return
		pins.forEach(function (pin) {
			if (pin.contains(e.target)) {
				Map.toggleClass(pin)
				if (pin.hasAttribute("data-js-map-pin-modal") && pin.getAttribute("data-js-map-pin-modal")) {
					let modal = document.querySelector(pin.getAttribute("data-js-map-pin-modal"))
					Map.toggleClass(modal)
				}
			}
		})
		modalCloseButtons.forEach(function (closeButton) {
			if (closeButton.contains(e.target)) {
				let pin = document.querySelector("[data-js-map-pin].is-active")
				Map.toggleClass(pin)
				let modal = closeButton.closest(".map-modal")
				Map.toggleClass(modal)
			}
		})
		if (mapBackground.contains(e.target)) {
			Map.closeAllPinsAndModals()
		}

	},

	toggleClass: (element, selector = cssActiveClass) => {
		if (!element) return
		if (!element.classList.contains(selector)) {
			if (element.hasAttribute("data-js-map-pin") && document.querySelector("[data-js-map-pin]." + cssActiveClass)) {
				Map.closeAllPinsAndModals()
			}
			element.classList.add(selector)
		} else {
			element.classList.remove(selector)
		}
	},

	closeAllPinsAndModals: () => {
		pins.forEach((pin) => {
			pin.classList.remove(cssActiveClass)
		})
		modals.forEach((modal) => {
			modal.classList.remove(cssActiveClass)
		})
	},

	nbEntreprises: () => {

		modals.forEach(function(map_modal){
			let nbEntreprises = map_modal.querySelectorAll(".map-modal__title");
			document.querySelector('[data-js-map-pin-modal="#' + map_modal.id + '"]').innerHTML = nbEntreprises.length
		})

	}
}

export default Map
