const accessibilityButtons = document.querySelectorAll("[data-js-accessibility-button]")
const cssClassName = "accessibility-enabled"

const Accessibility = {
	init: () => {},

	handleClick: (e) => {
		if (!accessibilityButtons.length) return
		accessibilityButtons.forEach(function (button) {
			if (button.contains(e.target)) {
				e.preventDefault()
				Accessibility.toggleMode()
			}
		})
	},

	toggleMode: () => {
		if (document.body.classList.contains(cssClassName)) {
			document.body.classList.remove(cssClassName)
		} else {
			document.body.classList.add(cssClassName)
		}
	},
}

export default Accessibility
