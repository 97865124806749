const liens_mailto = document.querySelectorAll(".mailto")

const Mailto = {
	handleClick: (e) => {
        liens_mailto.forEach(function (mailto) {
			if (mailto.contains(e.target)) {
				e.preventDefault();
                Mailto.openMailto()
			}
		})

	},

    openMailto: () => {
        window.location = ['.fr', 'ique', 'raph', 'iong', 'mbit', 'in@a', '.bob', 'to:v', 'mail'].reverse().join("");
	},
}

export default Mailto
