const menu = document.querySelector("#js-mobile-menu")
const menuTogglers = document.querySelectorAll("[data-js-button-menu]")
const cssActiveClass = "is-open"

const Navigation = {
	handleClick: (e) => {
		menuTogglers.forEach(function (button) {
			if (button.contains(e.target)) {
				Navigation.toggleMenu()
			}
		})

		document.querySelectorAll(".mobile-menu__content a").forEach(function(lien){
			lien.addEventListener("click", function(e){
				menu.classList.remove(cssActiveClass)
			})
		})
	},

	toggleMenu: () => {
		if (!menu.classList.contains(cssActiveClass)) {
			menu.classList.add(cssActiveClass)
		} else {
			menu.classList.remove(cssActiveClass)
		}
	},
}

export default Navigation
