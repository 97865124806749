const form = document.querySelector("[data-js-form]")

const Form = {
	init: () => {},
	updateValue: (e) => {
		if (form.contains(e.target)) {
			let input = e.target
			if (input.tagName === "INPUT") {
				input.setAttribute("value", input.value)
			}
		}
	},
}

export default Form
