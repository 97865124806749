import Swiper, { Navigation, Pagination } from "swiper"

const News = {
	init: () => {
		return new Swiper(".news-swiper", {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 48,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".swiper-pagination--orange",
				clickable: true,
				bulletElement: "button",
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 24,
				},
				992: {
					slidesPerView: 3,
					shortSwipes: false,
					followFinger: false,
				},
			},
		})
	},
}

export default News
