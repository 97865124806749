const header = document.getElementById("site-header")
const stickStart = header ? header.clientHeight * 2 : 200
// const scrollDirectionClass = "scroll-direction--"
const bodyStickyClass = "has-sticky-header"
// let lastScrollY = 0

const StickyHeader = {
	init: () => {
		if (!header) return
		StickyHeader.handleScroll()
	},

	/**
	 *
	 * @param {ScrollEvent} e
	 * @returns
	 */
	handleScroll: (e) => {
		if (!header) return
		if (StickyHeader.isBeyondStickStart(StickyHeader.getScrollY())) {
			StickyHeader.addStickyClasses()
		} else {
			StickyHeader.removeStickyClasses()
		}
	},

	/**
	 * Whether scroll is beyond the sticky starting point or not
	 * @returns boolean
	 */
	isBeyondStickStart: (scrollY) => {
		return scrollY >= stickStart
	},

	/**
	 * Check scroll direction
	 * @returns boolean True if going down
	 */
	// isScrollDown: (scrollY) => {
	// 	let isScrollDown = scrollY > lastScrollY
	// 	lastScrollY = scrollY <= 0 ? 0 : scrollY // For Mobile or negative scrolling
	// 	return isScrollDown
	// },

	/**
	 *
	 * @returns value of scrollY
	 */
	getScrollY: () =>
		window.scrollY !== undefined ? window.scrollY : (document.documentElement || document.body.parentNode || document.body).scrollTop,

	/**
	 * Add animations according to scroll direction
	 * @param {boolean} isScrollDown
	 */
	// addAnimation: (isScrollDown) => {
	// 	let scrollDirection = isScrollDown ? "down" : "up"
	// 	let otherDirection = isScrollDown ? "up" : "down"
	// 	header.classList.remove(scrollDirectionClass + otherDirection)
	// 	header.classList.add(scrollDirectionClass + scrollDirection)
	// },

	/**
	 * Add CSS Class for sticky header
	 */
	addStickyClasses: () => {
		document.body.classList.add(bodyStickyClass)
	},

	/**
	 * Remove CSS Class for sticky header
	 */
	removeStickyClasses: () => {
		document.body.classList.remove(bodyStickyClass)
		// header.classList.remove(scrollDirectionClass + "up")
		// header.classList.remove(scrollDirectionClass + "down")
	},
}

export default StickyHeader
