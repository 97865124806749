import throttle from "./js/utilities/throttle"
import Accessibility from "./js/accessibility"
import Animations from "./js/animations"
import Navigation from "./js/navigation"
import StickyHeader from "./js/sticky-header"
import TestimonySlider from "./js/testimony-slider"
import Map from "./js/map"
import Modal from "./js/modal"
import News from "./js/news"
import Video from "./js/video"
import Form from "./js/form"
import Mailto from "./js/mailto"

/**
 * onLoad events
 */
window.addEventListener("load", function () {
	Animations.allowTransitions()
})

/**
 * onDOMLoaded events
 */
document.addEventListener("DOMContentLoaded", function () {
	StickyHeader.init()
	TestimonySlider.init()
	Modal.init()
	News.init()
	Animations.init()
	Map.nbEntreprises()
})

/**
 * onClick events
 */
document.addEventListener("click", function (e) {
	Accessibility.handleClick(e)
	Map.handleClick(e)
	Modal.handleClick(e)
	Navigation.handleClick(e)
	// Video.handleClick(e)
	Mailto.handleClick(e)
})

/**
 * onScroll events
 */
document.addEventListener(
	"scroll",
	throttle(function (e) {
		StickyHeader.handleScroll(e)
	}, 100)
)

/**
 * onKeyUp events
 */
document.addEventListener(
	"keyup",
	throttle(function (e) {
		Form.updateValue(e)
		Modal.handleKeyboard(e)
	}, 100)
)

/**
 * Responsive table
 */

new basictable('table',{
	breakpoint: 768,
});
