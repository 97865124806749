const openers = document.querySelectorAll("[data-js-open-modal]")
const closers = document.querySelectorAll("[data-js-close-modal]")
const cssActiveClass = "is-active"

const Modal = {
	init: () => {
		Modal.openFromURL()
	},
	handleClick: (e) => {
		openers.forEach(function (opener) {
			if (opener.contains(e.target)) {
				if (opener.hasAttribute("data-js-open-modal")) {
					let selector = opener.dataset.jsOpenModal
					let modal = Modal.findModal(selector)
					Modal.open(modal)
				}
			}
		})
		closers.forEach(function (closer) {
			if (closer.contains(e.target)) {
				if (closer.hasAttribute("data-js-close-modal")) {
					let modal = closer.closest(".modal")
					Modal.close(modal)
				}
			}
		})
	},

	handleKeyboard: (e) => {
		if (e.key == "Escape") {
			let modal = document.querySelector(".modal." + cssActiveClass)
			if (modal) {
				Modal.close(modal)
			}
		}
	},

	open: (modal) => {
		let openedModal = document.querySelector(".modal." + cssActiveClass)
		if (openedModal) {
			Modal.close(openedModal)
		}
		if (!modal.classList.contains(cssActiveClass)) {
			modal.classList.add(cssActiveClass)
		}
	},

	close: (modal) => {
		if (modal.classList.contains(cssActiveClass)) {
			modal.classList.remove(cssActiveClass)
			Modal.removeHashFromURL()
		}
	},

	findModal: (selector) => {
		let modal = selector ? document.querySelector(selector) : null
		if (modal) return modal
		throw new Error("La modal avec le sélécteur '" + selector + "' n'existe pas.")
	},

	openFromURL: () => {
		let hash = window.location.hash
		if (hash) {
			let modal = document.querySelector(hash)
			if (modal && modal.classList.contains("modal")) {
				Modal.open(modal)
			}
		}
	},

	removeHashFromURL: () => {
		if (window.location.hash) {
			history.pushState("", document.title, window.location.pathname + window.location.search)
		}
	},
}

export default Modal
